.img {
    height: 170px;
    width: 180px;
}
.err-title {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
}
.err-link {
    text-decoration: none;
}
.err-sub-title {
    font-size: 10px;
    font-weight: normal;
    color: #707070;
    text-align: center;
}
.err-card {
    width: 140px;
}
