p {
    margin: 0;
}
.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.Image {
    height: 109px;
    width: 108px;
}
.title {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    margin-top: 30px;
}
.sub-title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0;
    color: #707070;
    margin-top: 14px;
}
.divider {
    border-bottom: 2px dashed #fff2db;
    width: 260px;
    margin-top: 35px;
    margin-bottom: 35px;
}
.card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 50px;
}
.cau-img-container {
    height: 29px;
    width: 29px;
    background: #EFEFEF;
    border-radius: 50px;
    margin-right: 10px;
}
.cau-img {
    height: 19px;
    width: 19px;
    padding: 5px;
}
.sub-title-1 {
    font-size: 12px;
    font-weight: normal;
    color: #707070;
}
.sub-title-2 {
    font-size: 12px;
    margin-top: 2px;
}

